import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { t, Trans } from "@lingui/macro";

import { FormControl, FormLabel } from "@chakra-ui/form-control";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormHelperText,
  HStack,
  // Select,
  Stack,
} from "@chakra-ui/react";

import { intersect, toggleMany } from "shared/lib/array";

import { ModelId } from "types";

import {
  countModels,
  getAllModels,
  getMaxOptions,
  ModelsGrouped,
  ModelsPreset,
  pickModelId,
  toFlatModelsGroups,
} from "entities/model";
import { i18nModelNames } from "entities/model/i18n";
import { AnimatePresence, motion } from "framer-motion";
import { useController, useFormContext } from "react-hook-form";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";

import { FormValues } from "../types";
import { GroupBox } from "./group-box";

type Props = {
  presets: ModelsPreset[];
  groupedModels: ModelsGrouped;
};

const ONE_CHECKBOX_HEIGHT = 1.5; // {number} in rem

export const ModelSelectType: React.VFC<Props> = memo(
  ({ presets, groupedModels }) => {
    const { control } = useFormContext<FormValues>();

    const { flatGroup, totalCount, allModels, maxOptions } = useMemo(() => {
      return {
        flatGroup: toFlatModelsGroups(groupedModels),
        totalCount: countModels(groupedModels),
        allModels: getAllModels(groupedModels),
        maxOptions: getMaxOptions(groupedModels),
      };
    }, [groupedModels]);

    const options = useMemo(() => {
      const values = presets.map(({ name }) => ({
        value: name,
        label: t({ id: name }),
      }));
      return values.concat([{ value: "custom", label: t`Custom` }]);
    }, [presets]);

    const modelsController = useController({
      name: "displayProperties.models",
      control,
      defaultValue: allModels,
      rules: {
        required: true,
      },
    });

    const [selectValue, setSelectValue] = useState(() => options[0]);

    const [activeGroup, setActiveGroup] = useState(() => flatGroup[0].name);

    const currentCheckBoxes = useMemo(() => {
      return {
        id: uuidv4(),
        items: flatGroup.filter(({ name }) => name === activeGroup)[0].models,
      };
    }, [flatGroup, activeGroup]);

    const selectedModels = modelsController.field.value as ModelId[];

    useEffect(() => {
      if (selectedModels.length === 0) {
        setSelectValue({ value: "", label: t`Select type` });
        return;
      }

      const maybePreset = presets.find(
        (preset) =>
          preset.models.every((modelId) => selectedModels.includes(modelId)) &&
          preset.models.length === selectedModels.length
      );

      if (maybePreset)
        setSelectValue({
          value: maybePreset.name,
          label: t({ id: maybePreset.name }),
        });
      else setSelectValue({ value: "custom", label: t`Custom` });
    }, [selectedModels]);

    const handleChangeCheckBox = (id: ModelId, isChecked: boolean) => {
      if (isChecked) {
        modelsController.field.onChange(
          selectedModels.filter((modelId) => modelId !== id)
        );
      } else modelsController.field.onChange([...selectedModels, id]);
    };

    const handleClear = useCallback(
      () => modelsController.field.onChange([]),
      []
    );

    const handleSelectAll = useCallback(
      () => modelsController.field.onChange(allModels),
      [allModels]
    );

    return (
      <Stack spacing={4} flex={1}>
        <FormControl isRequired>
          <FormLabel htmlFor="project-type">
            <Trans>Project type</Trans>
          </FormLabel>
          <Select
            id="project-type"
            onChange={(option) => {
              if (!option) return;
              setSelectValue(option);
              const group = presets.find(({ name }) => name === option.value);
              if (group) modelsController.field.onChange(group.models);
            }}
            placeholder={t`Select type`}
            components={{ IndicatorSeparator: () => null }}
            value={selectValue}
            options={options}
          />
        </FormControl>
        <FormControl>
          <FormLabel display="flex" alignItems="center" mb={0}>
            <Trans>Selected models</Trans>
            <FormHelperText mt={0} ml={2} as="span">
              {selectedModels.length}/{totalCount}
            </FormHelperText>
          </FormLabel>
          <FormHelperText mt={0}>
            <Trans>These models will be added to your project</Trans>
          </FormHelperText>
        </FormControl>
        <HStack justifyContent="space-between">
          {flatGroup.map(({ name, models, icon }) => {
            const activeCount = intersect(
              models.map(pickModelId),
              selectedModels
            ).length;

            return (
              <GroupBox
                isActive={name === activeGroup}
                count={activeCount}
                icon={icon}
                key={name}
                onHover={() => setActiveGroup(name)}
                onClick={() =>
                  modelsController.field.onChange(
                    toggleMany(
                      selectedModels,
                      models.map(pickModelId),
                      activeCount === models.length ? "remove" : "add"
                    )
                  )
                }
              />
            );
          })}
        </HStack>

        <Box minH={`${ONE_CHECKBOX_HEIGHT * maxOptions}rem`}>
          <AnimatePresence>
            <motion.div
              layout="position"
              initial="hidden"
              animate="visible"
              variants={list}
            >
              {currentCheckBoxes.items.map(({ id }) => {
                const isChecked = selectedModels.includes(id);
                return (
                  <motion.div key={id} variants={item}>
                    <Checkbox
                      isChecked={isChecked}
                      onChange={() => handleChangeCheckBox(id, isChecked)}
                    >
                      <Trans id={i18nModelNames[id]} />
                    </Checkbox>
                  </motion.div>
                );
              })}
            </motion.div>
          </AnimatePresence>
        </Box>
        <ButtonGroup variant="outline" size="sm" alignSelf="flex-end">
          <Button onClick={handleClear}>
            <Trans>Clear</Trans>
          </Button>
          <Button onClick={handleSelectAll}>
            <Trans>Select all</Trans>
          </Button>
        </ButtonGroup>
      </Stack>
    );
  }
);

const list = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.05,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

const item = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};
