import { useDisclosure } from "@chakra-ui/hooks";
import { IconButton } from "@chakra-ui/react";

import { FiEdit } from "@react-icons/all-files/fi/FiEdit";

import { ModalProjectEdit } from "components/modals/project/edit";

import { BaseProject } from "types";

import { IconContainer } from "./ui";

type Props = {
  projectId: string;
  baseProject: BaseProject;
};

export const ProjectEditButton: React.VFC<Props> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton
        aria-label="edit-project"
        position="absolute"
        top={0}
        right={0}
        mt={8}
        mr={28}
        size="sm"
        variant="outline"
        colorScheme="blue"
        as={IconContainer}
        icon={<FiEdit />}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onOpen();
        }}
      />
      <ModalProjectEdit onClose={onClose} isOpen={isOpen} {...props} />
    </>
  );
};
