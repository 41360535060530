import Link from "next/link";

import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { useColorMode } from "@chakra-ui/color-mode";
import { Box, Flex, Heading, Stack, Text } from "@chakra-ui/react";

import { useIsAdmin } from "models/keycloak";

import { getDateLocale, matchLocale } from "shared/lib/i18n";

import { Project } from "types";

import { format } from "date-fns";

import { Badge } from "./badge";
import { DateMark } from "./date-mark";
import { DeleteProjectButton } from "./delete-button";
import { ProjectEditButton } from "./edit-button";
import { ProjectInfoCounts } from "./processing-info";
import { ProjectAuthor } from "./project-author";
import { Card } from "./ui";

const bg = { light: "white", dark: "gray.700" };

const formatDate = "M/d/y, HH:mm:ss ";

export const ProjectCard: React.FC<Project> = (project) => {
  const isAdmin = useIsAdmin();

  const { colorMode } = useColorMode();
  const { i18n } = useLingui();

  const {
    id,
    name,
    description,
    processingCount,
    failedProcessingCount,
    succeedProcessingCount,
    demoSource,
    demo,
    created,
    updated,
    author,
  } = project;

  const borderColor = demo
    ? demoSource
      ? "yellow.500"
      : "blue.500"
    : bg[colorMode];

  const createdTooltipText = `${t`Created`} ${format(
    new Date(created),
    formatDate,
    { locale: getDateLocale(matchLocale(i18n.locale)) }
  )}`;

  return (
    <Link href={`/projects/${id}`} passHref>
      <Card
        as="a"
        data-cy={`project-card-${id}`}
        data-component="project-card"
        shadow="md"
        borderRadius="ui-base"
        pl={8}
        pr={28}
        pt={8}
        pb={6}
        bg={bg[colorMode]}
        border="1px solid"
        borderColor={borderColor}
        _hover={{
          transform: "scale(1.025)",
          shadow: "lg",
        }}
      >
        <Badge
          iconName={project.displayProperties?.icon}
          bgColor={project.displayProperties?.color}
          boxProps={{
            position: "absolute",
            right: 2,
            top: 2,
          }}
        />
        <Flex h="full" direction="column" justify="space-between">
          <Flex justifyContent="space-between" alignItems="center">
            <Box w="full">
              <Heading maxW="200px" as="h2" fontSize="xl" mt={1} noOfLines={1}>
                {name}
              </Heading>
              <ProjectEditButton projectId={id} baseProject={project} />
              {/* <Editable
                loading={renameLoading}
                height="32px"
                value={name}
                onSubmit={handleRename}
                controlsProps={{ as: IconContainer }}
                inputProps={{ isRequired: true, maxLength: 30 }}
                previewProps={{
                  as: "h2",
                  fontSize: "xl",
                  fontWeight: "800",
                  maxWidth: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              /> */}
              <Text
                mt={2}
                fontSize="sm"
                color="gray.500"
                w="full"
                overflow="hidden"
                textOverflow="ellipsis"
                noOfLines={2}
              >
                {description}
              </Text>
            </Box>
            <DeleteProjectButton isDemo={!!demoSource} projectId={id} />
          </Flex>
          <Stack mt={2} spacing={2}>
            <ProjectInfoCounts
              processingCount={processingCount}
              succeedProcessingCount={succeedProcessingCount}
              failedProcessingCount={failedProcessingCount}
            />
            <DateMark created={createdTooltipText} updated={updated} />
          </Stack>
        </Flex>
        {isAdmin && <ProjectAuthor author={author.email} />}
      </Card>
    </Link>
  );
};
