import { useMutation, useQueryClient } from "react-query";

import { t } from "@lingui/macro";

import { IconButton } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import { useToast } from "@chakra-ui/toast";

import { FiTrash2 } from "@react-icons/all-files/fi/FiTrash2";

import ModalProjectDelete from "components/modals/delete-project";

import { useIsAdmin } from "models/keycloak";

import { deleteProject } from "shared/api/client";

import { IconContainer } from "./ui";

type Props = { projectId: string; isDemo: boolean };

export const DeleteProjectButton = ({ projectId, isDemo }: Props) => {
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const { mutate: onDelete, isLoading } = useMutation(deleteProject, {
    onSuccess: async () => {
      queryClient.invalidateQueries("projects");

      toast({
        title: t`Project deleted`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    },
    onError: (e) => {
      console.error(e);

      toast({
        title: t`Error delete project`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  const handleShowDeleteAlert = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onOpen();
  };

  const handleDelete = () => {
    onDelete(projectId);
    onClose();
  };

  const isAdmin = useIsAdmin();

  const isHiddenForUserRole = isDemo && !isAdmin;

  return (
    <>
      {!isHiddenForUserRole && (
        <IconButton
          data-cy="project-card-delete-button"
          position="absolute"
          top={0}
          right={0}
          mt={8}
          mr={16}
          size="sm"
          onClick={handleShowDeleteAlert}
          as={IconContainer}
          aria-label="project-options"
          variant="outline"
          icon={<FiTrash2 />}
          colorScheme="red"
          isLoading={isLoading}
        />
      )}
      <ModalProjectDelete
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleDelete}
      />
    </>
  );
};
