import { memo } from "react";

import { t, Trans } from "@lingui/macro";

import {
  Box,
  FormLabel,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Switch,
  VStack,
} from "@chakra-ui/react";

import { BaseProject, Project } from "types";

import { FormProvider, useForm } from "react-hook-form";

import {
  ColorPicker,
  IconPicker,
  InputDescription,
  InputName,
  SubmitButton,
  TitleModal,
} from "../form-elements";
import { FormValues } from "../types";

type Props = {
  onSubmit: (data: FormValues) => Promise<Project> | void;
  isLoading: boolean;
  isAdmin: boolean;
  baseProject: BaseProject;
};

export const FormEditProject: React.VFC<Props> = memo(
  ({ onSubmit, baseProject, isLoading, isAdmin }) => {
    const methods = useForm<FormValues>({
      mode: "all",
      defaultValues: baseProject,
    });

    return (
      <FormProvider {...methods}>
        <Box px={12} pt={8} pb={4} pos="relative">
          <TitleModal />
          <ModalCloseButton
            data-cy="project-form__close-button"
            m="1.9rem"
            top={0}
            right={0}
          />
        </Box>
        <ModalBody py={0} px={12}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <HStack spacing={8} flex={1} alignItems="flex-start">
              <VStack flex={1}>
                <Box width="full" position="relative">
                  <InputName />
                  <Box position="absolute" top="0" right="0">
                    <IconPicker />
                    <ColorPicker />
                  </Box>
                </Box>
                <InputDescription />
                {isAdmin && (
                  <HStack
                    spacing={3}
                    pt={4}
                    alignSelf="flex-start"
                    justifyContent="center"
                  >
                    <Switch
                      data-cy="project-form__demo-switch"
                      id="is-demo"
                      h={5}
                      {...methods.register("demoSource")}
                    />
                    <FormLabel htmlFor="is-demo" fontSize="lg">
                      <Trans>Is demo project</Trans> (only for Admin)
                    </FormLabel>
                  </HStack>
                )}
              </VStack>
            </HStack>

            <ModalFooter px={0} pb={6} mt={2}>
              <SubmitButton isLoading={isLoading} text={t`Save`} />
            </ModalFooter>
          </form>
        </ModalBody>
      </FormProvider>
    );
  }
);
