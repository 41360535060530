import { memo, useMemo } from "react";

import { t, Trans } from "@lingui/macro";

import {
  Box,
  CircularProgress,
  Flex,
  FormLabel,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Switch,
  VStack,
} from "@chakra-ui/react";

import { FiAlertTriangle } from "@react-icons/all-files/fi/FiAlertTriangle";

import NotIdealState from "components/not-ideal-state";
import { makeGroupModels } from "components/wizard/select-model/lib";

import { useSchema } from "shared/api/client/hook";

import { Project } from "types";

import { encodeModels, groupSchemaByModelPresets } from "entities/model";
import { FormProvider, useForm } from "react-hook-form";

import {
  ColorPicker,
  IconPicker,
  InputDescription,
  InputName,
  ModelSelectType,
  SubmitButton,
  TitleModal,
} from "../form-elements";
import { FormValues } from "../types";

type Props = {
  onSubmit: (data: FormValues) => Promise<Project> | void;
  isLoading: boolean;
  isAdmin: boolean;
};

export const FormCreateProject: React.VFC<Props> = memo(
  ({ onSubmit, isLoading, isAdmin }) => {
    const methods = useForm<FormValues>({
      mode: "all",
      defaultValues: {
        name: "New project",
      },
    });

    const schemaQuery = useSchema();

    const { modelPresets, modelGroups } = useMemo(() => {
      if (schemaQuery.isLoading || !schemaQuery.isSuccess) {
        return {
          modelPresets: [],
          modelGroups: {
            byBuildings: [],
            bySAMZoom: [],
            others: [],
          },
        };
      }

      return {
        modelPresets: groupSchemaByModelPresets(schemaQuery.data),
        modelGroups: makeGroupModels(schemaQuery.data.models),
      };
    }, [schemaQuery.data]);

    if (schemaQuery.isLoading) {
      return (
        <Box width="100%" py={8}>
          <NotIdealState spacing="4" title={t`Fetching models...`}>
            <CircularProgress capIsRound isIndeterminate />
          </NotIdealState>
        </Box>
      );
    }

    if (!schemaQuery.isSuccess) {
      return (
        <Box width="100%" py={8}>
          <NotIdealState
            icon={FiAlertTriangle}
            spacing="4"
            iconProps={{ color: "red.600" }}
            title={<Trans>Error</Trans>}
            description={<Trans>Could not fetch the models</Trans>}
          />
        </Box>
      );
    }

    const formSubmit = ({ displayProperties = {}, ...rest }: FormValues) => {
      onSubmit({
        ...rest,
        displayProperties: {
          ...displayProperties,
          models: encodeModels(
            schemaQuery.data,
            displayProperties.models || []
          ),
        },
      });
    };

    return (
      <FormProvider {...methods}>
        <Box px={12} pt={8} pb={4} pos="relative">
          <TitleModal />
          <ModalCloseButton
            data-cy="project-form__close-button"
            m="1.9rem"
            top={0}
            right={0}
          />
        </Box>
        <ModalBody py={0} px={12}>
          <form onSubmit={methods.handleSubmit(formSubmit)}>
            <HStack spacing={8} alignItems="flex-start">
              <VStack flex="0 1 50%">
                <Box width="full" position="relative">
                  <InputName />
                  <Box position="absolute" top="0" right="0">
                    <IconPicker />
                    <ColorPicker />
                  </Box>
                </Box>
                <InputDescription />
                {isAdmin && (
                  <HStack
                    spacing={3}
                    pt={4}
                    alignSelf="flex-start"
                    justifyContent="center"
                  >
                    <Switch
                      data-cy="project-form__demo-switch"
                      id="is-demo"
                      h={5}
                      {...methods.register("demoSource")}
                    />
                    <FormLabel htmlFor="is-demo" fontSize="lg">
                      <Trans>Is demo project</Trans> (only for Admin)
                    </FormLabel>
                  </HStack>
                )}
              </VStack>
              <Flex flex="0 1 50%">
                <ModelSelectType
                  presets={modelPresets}
                  groupedModels={modelGroups}
                />
              </Flex>
            </HStack>

            <ModalFooter px={0} pb={6} mt={2}>
              <SubmitButton isLoading={isLoading} text={t`Create`} />
            </ModalFooter>
          </form>
        </ModalBody>
      </FormProvider>
    );
  }
);
