import React from "react";

import { Avatar, Box, Tooltip, useClipboard } from "@chakra-ui/react";

import { FiCheckCircle } from "@react-icons/all-files/fi/FiCheckCircle";

export const ProjectAuthor: React.FC<{ author: string }> = ({ author }) => {
  const { onCopy, hasCopied } = useClipboard(author);

  return (
    <Box position="absolute" bottom={5} right={5}>
      <Tooltip
        aria-label="copy"
        label={hasCopied ? "Copied!" : `Author: ${author}. Click to copy`}
        bg={hasCopied ? "green.500" : "gray.100"}
        color={hasCopied ? "white" : "black"}
      >
        <Box>
          {hasCopied ? (
            <Box size="20px" p="2px" as={FiCheckCircle} color="green.500" />
          ) : (
            <Avatar
              size="xs"
              onClick={(e) => {
                e.preventDefault();
                onCopy();
              }}
            />
          )}
        </Box>
      </Tooltip>
    </Box>
  );
};
