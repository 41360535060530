import { persist } from "effector-storage/query";

import root from "models/root";
import { historyChanged } from "models/router";

import { setPayload } from "shared/lib/effector/helpers";
import { reset } from "shared/lib/effector/reset";

import { SortBy, SortOrder } from "types";

const projectsDomain = root.createDomain("projects");

const { createEvent, createStore } = projectsDomain;

export const pageChanged = createEvent<number>();
export const pageSizeChanged = createEvent<number>();

export const sortChanged = createEvent<SortBy>();
export const sortOrderChanged = createEvent<SortOrder>();

export const $page = createStore(1);
export const $pageSize = createStore<number>(10);

export const $sortBy = createStore<SortBy>(SortBy.updated);
export const $sortOrder = createStore<SortOrder>(SortOrder.desc);

$sortBy.on(sortChanged, setPayload);
$sortOrder.on(sortOrderChanged, setPayload);
$page.on(pageChanged, setPayload).reset(pageSizeChanged);
$pageSize.on(pageSizeChanged, setPayload);

reset({
  clock: historyChanged,
  target: [$page, $pageSize, $sortBy, $sortOrder],
});

persist({ key: "page", store: $page });
persist({ key: "page_size", store: $pageSize });
persist({ key: "sort_by", store: $sortBy });
persist({ key: "sort_order", store: $sortOrder });
