import React, { useCallback, VFC } from "react";
import { useRouter } from "next/router";

import { AxiosResponse } from "axios";
import { useQueryClient } from "react-query";

import { t } from "@lingui/macro";

import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/modal";

import { useIsAdmin } from "models/keycloak";

import { createProject } from "shared/api/client";
import { APIError } from "shared/lib/errors";
import { useEnchancedMutation } from "shared/lib/hooks/use-enchanced-mutation";

import { BaseProject, Project } from "types";

import { FormCreateProject } from "./form";

type Props = Omit<ModalProps, "children">;

export const ModalProjectCreate: VFC<Props> = (modalProps) => {
  const queryClient = useQueryClient();
  const isAdmin = useIsAdmin();

  const router = useRouter();

  const { isLoading, mutate } = useEnchancedMutation<
    AxiosResponse<Project>,
    APIError,
    BaseProject
  >({
    mutationFunction: createProject,
    onSuccess: async ({ data }) => {
      router.push(`/projects/${data.id}`);
      queryClient.invalidateQueries("projects");
    },
    toast: {
      msgError: t`Error create project`,
    },
  });

  const handleSubmit = useCallback(
    (project: BaseProject) => mutate(project),
    [mutate]
  );

  return (
    <Modal isCentered size="4xl" {...modalProps}>
      <ModalOverlay />
      <ModalContent borderRadius="ui-base">
        <FormCreateProject
          onSubmit={handleSubmit}
          isLoading={isLoading}
          isAdmin={isAdmin}
        />
      </ModalContent>
    </Modal>
  );
};
