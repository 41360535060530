import React, { useCallback, VFC } from "react";

import { AxiosResponse } from "axios";
import { useQueryClient } from "react-query";

import { t } from "@lingui/macro";

import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/modal";

import { useIsAdmin } from "models/keycloak";

import { updateProject } from "shared/api/client";
import { APIError } from "shared/lib/errors";
import { useEnchancedMutation } from "shared/lib/hooks/use-enchanced-mutation";

import { BaseProject, Project } from "types";

import { FormEditProject } from "./form";

type Props = Omit<ModalProps, "children"> & {
  projectId: string;
  baseProject: BaseProject;
};

export const ModalProjectEdit: VFC<Props> = ({
  projectId,
  baseProject,
  ...modalProps
}) => {
  const queryClient = useQueryClient();
  const isAdmin = useIsAdmin();

  const { isLoading, mutate } = useEnchancedMutation<
    AxiosResponse<Project>,
    APIError,
    BaseProject
  >({
    mutationFunction: (project: BaseProject) =>
      updateProject(projectId, project),
    onSuccess: async () => {
      modalProps.onClose();
      queryClient.invalidateQueries("projects");
    },
    toast: {
      msgError: t`Error edit project`,
    },
  });

  const handleSubmit = useCallback(
    (project: BaseProject) => mutate(project),
    [mutate]
  );

  return (
    <Modal isCentered size="lg" {...modalProps}>
      <ModalOverlay />
      <ModalContent borderRadius="ui-base">
        <FormEditProject
          onSubmit={handleSubmit}
          isLoading={isLoading}
          isAdmin={isAdmin}
          baseProject={baseProject}
        />
      </ModalContent>
    </Modal>
  );
};
